const urlUtility = {
  /**
   *
   * @param {String} url
   * @returns {String}
   */
  getYouTubeVideoId(url) {
    const regex =
      /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    return regex.exec(url)[3];
  },
};

export default urlUtility;
