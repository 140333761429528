<template>
  <div>
    <v-alert prominent type="error">
      <span>Sorry, an error occurred</span>
    </v-alert>
    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>Detail</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-card-title>
              <span>Message</span>
            </v-card-title>
            <v-card-text>
              <span v-text="message" />
            </v-card-text>
            <v-card-subtitle>
              <span>Code</span>
            </v-card-subtitle>
            <v-card-text>
              <span v-text="code" />
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { errorUtility } from '@/utils';

export default {
  name: 'TheErrorAlert',
  props: {
    error: {
      type: Error,
      required: true,
      note: 'The error to display',
    },
  },
  computed: {
    processedError() {
      return errorUtility.process(this.error);
    },
    message() {
      return this.processedError.data.message;
    },
    code() {
      return this.processedError.status;
    },
  },
};
</script>
