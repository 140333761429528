export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  certificate: (state) => state.certificate,
  course: (state) => state.course,
  courses: (state) => state.courses,
  lesson: (state) => state.lesson,
  lessons: (state) => (state.course ? state.course.lessons : []),
  quizzes: (state) => state.quizzes,
  result: (state) => state.result,
  summary: (state) => state.summary,
};
