import { coursesApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initCertifiedTrainingDashboard({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchCourses'),
      dispatch('fetchCertificate'),
      dispatch('fetchSummary'),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchCertificate({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await coursesApi.requestCertificate(token);
      commit('setCertificate', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchSummary({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await coursesApi.requestSummary(token);
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchCourses({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await coursesApi.requestCourseList(token);
      commit('addCourses', response.data.courses);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} courseId
   */
  async initCourseDetail({ commit, dispatch }, courseId) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchCourseDetail', courseId)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} courseId
   */
  async fetchCourseDetail({ commit, rootGetters }, courseId) {
    const token = rootGetters['auth/token'];
    try {
      const response = await coursesApi.requestCourseDetail({
        token,
        courseId,
      });
      commit('setCourse', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} courseId
   * @param {String} lessonId
   */
  async initLessonDetail({ commit, dispatch }, { courseId, lessonId }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchLessonDetail', { courseId, lessonId })];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} courseId
   * @param {String} lessonId
   */
  async fetchLessonDetail({ commit, rootGetters }, { courseId, lessonId }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await coursesApi.requestLessonDetail({
        token,
        courseId,
        lessonId,
      });
      commit('setLesson', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} courseId
   * @param {String} lessonId
   */
  async notarizeLesson({ commit, rootGetters }, { courseId, lessonId }) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const response = await coursesApi.requestNotarizeLesson({
        token,
        courseId,
        lessonId,
      });
      return response.data.lesson_watched;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} courseId
   * @param {String} lessonId
   */
  async initLessonQuizzes({ commit, dispatch }, { courseId, lessonId }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchLessonQuizzes', { courseId, lessonId })];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} courseId
   * @param {String} lessonId
   */
  async fetchLessonQuizzes({ commit, rootGetters }, { courseId, lessonId }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await coursesApi.requestLessonQuizzes({
        token,
        courseId,
        lessonId,
      });
      commit('setLesson', {
        id: response.data.lesson_id,
        title: response.data.lesson_title,
      });
      commit('setQuizzes', response.data.quizzes);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} courseId
   * @param {String} lessonId
   * @param {Object} data
   */
  async answerQuizzes({ commit, rootGetters }, { courseId, lessonId, data }) {
    const token = rootGetters['auth/token'];
    commit('setLoading', true);
    try {
      const response = await coursesApi.requestAnswerQuizzes({
        token,
        courseId,
        lessonId,
        data,
      });
      commit('setResult', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
};
