const errorUtility = {
  /**
   *
   * @param {String} message
   * @param {Number} code
   * @param {Object} data
   * @returns {CustomError}
   */
  buildCustomError(message, code, data) {
    return new CustomError(message, code, data);
  },
  /**
   *
   * @param {Error} error
   * @returns {Object}
   */
  process(error) {
    return error.response
      ? composeWithResponse(error)
      : composeNoResponse(error);
  },
};

export default errorUtility;

/**
 *
 * @param {Error} error
 * @returns {Object}
 */
const composeWithResponse = (error) => {
  const data = error.response.data;
  return {
    data: {
      message:
        data && data.message
          ? data.message
          : data.error
          ? data.error
          : 'No message',
    },
    status: error.response.status ? error.response.status : 'No code',
  };
};

/**
 * @param {Error} error
 * @returns {Object}
 */
const composeNoResponse = (error) => {
  return {
    data: {
      message: error.request
        ? 'The request was made but no response was received'
        : 'Something happened in setting up the request that triggered an error',
    },
    status: 503,
  };
};

class CustomError extends Error {
  constructor(message, code, data) {
    super(message);
    this.code = code;
    this.response = new Response(code, data);
  }
}

class Response {
  constructor(status, data) {
    this.status = status;
    this.data = data;
  }
}
