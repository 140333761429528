import { APIS } from '@/constants';
import axios from 'axios';

const coursesApi = {
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestCourseList(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      method: 'get',
      baseURL: APIS.BASE_URL.COURSES,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} courseId
   * @returns {AxiosPromise}
   */
  requestCourseDetail({ token, courseId }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${courseId}`,
      method: 'get',
      baseURL: APIS.BASE_URL.COURSES,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} courseId
   * @param {String} lessonId
   * @returns {AxiosPromise}
   */
  requestLessonDetail({ token, courseId, lessonId }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${courseId}/lessons/${lessonId}`,
      method: 'get',
      baseURL: APIS.BASE_URL.COURSES,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} courseId
   * @param {String} lessonId
   * @returns {AxiosPromise}
   */
  requestNotarizeLesson({ token, courseId, lessonId }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${courseId}/lessons/${lessonId}/notarize`,
      method: 'post',
      baseURL: APIS.BASE_URL.COURSES,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} courseId
   * @param {String} lessonId
   * @returns {AxiosPromise}
   */
  requestLessonQuizzes({ token, courseId, lessonId }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${courseId}/lessons/${lessonId}/quizzes`,
      method: 'get',
      baseURL: APIS.BASE_URL.COURSES,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} courseId
   * @param {String} lessonId
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestAnswerQuizzes({ token, courseId, lessonId, data }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${courseId}/lessons/${lessonId}/quizzes`,
      method: 'post',
      baseURL: APIS.BASE_URL.COURSES,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestCertificate(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/certificate',
      method: 'get',
      baseURL: APIS.BASE_URL.COURSES,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestSummary(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/summary',
      method: 'get',
      baseURL: APIS.BASE_URL.COURSES,
      headers,
    });
  },
};

export default coursesApi;
