import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setCertificate(state, payload) {
    state.certificate = payload;
  },
  setCourse(state, payload) {
    state.course = payload;
  },
  setCourses(state, payload) {
    state.courses = payload;
  },
  addCourses(state, payload) {
    state.courses.push(...payload);
  },
  setLesson(state, payload) {
    state.lesson = payload;
  },
  setQuizzes(state, payload) {
    state.quizzes = payload;
  },
  setResult(state, payload) {
    state.result = payload;
  },
  setSummary(state, payload) {
    state.summary = payload;
  },
};
