const PATH = Object.freeze({
  ROOT: '/',
  LOGIN: '/login',
  NOT_FOUND: '/:notFound(.*)',
  ARCHIVE: '/archive',
  ARCHIVE_DASHBOARD: 'dashboard',
  CERTIFIED_TRAINING: '/certified-training',
  CERTIFIED_TRAINING_COURSE: 'course/:id',
  CERTIFIED_TRAINING_COURSE_DASHBOARD: 'dashboard/:id',
  CERTIFIED_TRAINING_COURSE_LESSON: 'lesson/:lessonId',
  CERTIFIED_TRAINING_COURSE_QUIZ: 'quiz/:lessonId',
  CERTIFIED_TRAINING_COURSE_RESULT: 'result',
  CERTIFIED_TRAINING_DASHBOARD: 'dashboard',
  DASHBOARD: '/dashboard',
  DB_EXPORT: '/db-export',
  OPERATOR_LOGS: '/operator-logs',
  OPERATOR_LOGS_DASHBOARD: 'dashboard',
  PRE_SCREENING: '/pre-screening',
  PRE_SCREENING_DASHBOARD: 'dashboard',
  PRE_SCREENING_VERIFY_KYB: 'verify-kyb',
  PRE_SCREENING_VERIFY_KYB_CDD: 'verify-kyb-cdd',
  PRE_SCREENING_VERIFY_KYB_DILISENSE: 'verify-kyb-dilisense',
  PRE_SCREENING_VERIFY_KYB_DOCS: 'verify-kyb-docs',
  PRE_SCREENING_VERIFY_KYB_FACECHECK: 'verify-kyb-facecheck',
  PRE_SCREENING_VERIFY_KYB_SUMMARY: 'verify-kyb-summary/:result',
  PRE_SCREENING_VERIFY_KYC: 'verify-kyc',
  PRE_SCREENING_VERIFY_KYC_CDD: 'verify-kyc-cdd',
  PRE_SCREENING_VERIFY_KYC_DILISENSE: 'verify-kyc-dilisense',
  PRE_SCREENING_VERIFY_KYC_DOCS: 'verify-kyc-docs',
  PRE_SCREENING_VERIFY_KYC_FACECHECK: 'verify-kyc-facecheck',
  PRE_SCREENING_VERIFY_KYC_SUMMARY: 'verify-kyc-summary/:result',
  RISK_ASSESSMENT: '/risk-assessment',
  RISK_ASSESSMENT_ASSIGN: 'assign',
  RISK_ASSESSMENT_BASIC: 'basic',
  RISK_ASSESSMENT_DASHBOARD: 'dashboard',
  RISK_ASSESSMENT_ENHANCED: 'enhanced',
  SAR: '/suspicious-activity-report',
  SAR_DASHBOARD: 'dashboard',
  SAR_MANAGE: 'manage/:id',
  SAR_NEW: 'new',
  SAR_NEW_COUNTERPARTY: 'counterparty',
  SAR_NEW_EFFECTS: 'effects',
  SAR_NEW_SAR: 'sar',
  SAR_NEW_TYPE: 'type',
  SAR_NEW_USER: 'user',
  TXS_MONITOR: '/txs-monitor',
  TXS_MONITOR_DASHBOARD: 'dashboard',
  TXS_MONITOR_DETAIL: 'detail/:id',
  TXS_MONITOR_SCENARIO: 'scenario',
  TXS_MONITOR_SCENARIO_DASHBOARD: 'dashboard',
  TXS_MONITOR_SCENARIO_MANAGE: 'manage/:id?',
  TXS_MONITOR_SCENARIO_MANAGE_DECISION: 'decision',
  TXS_MONITOR_SCENARIO_MANAGE_RULES: 'rules',
  TXS_MONITOR_SCENARIO_MANAGE_TRIGGER: 'trigger',
  VASP: '/vasp',
  VASP_DASHBOARD: 'dashboard',
  VASP_DETAIL: 'detail/:id',
  VASP_MANAGE: 'manage/:id',
  VASP_NEW: 'new',
  VERIFIABLE_CREDENTIALS: '/verifiable-credentials',
});

const NAME = Object.freeze({
  NOT_FOUND: 'not-found',
  LOGIN: 'login',
  ARCHIVE: 'archive',
  ARCHIVE_DASHBOARD: 'archive-dashboard',
  CERTIFIED_TRAINING: 'certified-training',
  CERTIFIED_TRAINING_COURSE: 'certified-training-course',
  CERTIFIED_TRAINING_COURSE_DASHBOARD: 'certified-training-course-dashboard',
  CERTIFIED_TRAINING_COURSE_LESSON: 'certified-training-course-lesson',
  CERTIFIED_TRAINING_COURSE_QUIZ: 'certified-training-course-quiz',
  CERTIFIED_TRAINING_COURSE_RESULT: 'certified-training-course-result',
  CERTIFIED_TRAINING_DASHBOARD: 'certified-training-dashboard',
  DASHBOARD: 'dashboard',
  DB_EXPORT: 'db-export',
  OPERATOR_LOGS: 'operator-logs',
  OPERATOR_LOGS_DASHBOARD: 'operator-logs-dashboard',
  PRE_SCREENING: 'pre-screening',
  PRE_SCREENING_DASHBOARD: 'pre-screening-dashboard',
  PRE_SCREENING_VERIFY_KYB: 'pre-screening-verify-kyb',
  PRE_SCREENING_VERIFY_KYB_CDD: 'pre-screening-verify-kyb-cdd',
  PRE_SCREENING_VERIFY_KYB_DILISENSE: 'pre-screening-verify-kyb-dilisense',
  PRE_SCREENING_VERIFY_KYB_DOCS: 'pre-screening-verify-kyb-docs',
  PRE_SCREENING_VERIFY_KYB_FACECHECK: 'pre-screening-verify-kyb-facecheck',
  PRE_SCREENING_VERIFY_KYB_SUMMARY: 'pre-screening-verify-kyb-summary',
  PRE_SCREENING_VERIFY_KYC: 'pre-screening-verify-kyc',
  PRE_SCREENING_VERIFY_KYC_CDD: 'pre-screening-verify-kyc-cdd',
  PRE_SCREENING_VERIFY_KYC_DILISENSE: 'pre-screening-verify-kyc-dilisense',
  PRE_SCREENING_VERIFY_KYC_DOCS: 'pre-screening-verify-kyc',
  PRE_SCREENING_VERIFY_KYC_FACECHECK: 'pre-screening-verify-kyc-facecheck',
  PRE_SCREENING_VERIFY_KYC_SUMMARY: 'pre-screening-verify-kyc-summary',
  RISK_ASSESSMENT: 'risk-assessment',
  RISK_ASSESSMENT_ASSIGN: 'risk-assessment-assign',
  RISK_ASSESSMENT_BASIC: 'risk-assessment-basic',
  RISK_ASSESSMENT_DASHBOARD: 'risk-assessment-dashboard',
  RISK_ASSESSMENT_ENHANCED: 'risk-assessment-enhanced',
  SAR: 'sar',
  SAR_DASHBOARD: 'sar-dashboard',
  SAR_MANAGE: 'sar-manage',
  SAR_NEW: 'sar-new',
  SAR_NEW_COUNTERPARTY: 'sar-new-counterparty',
  SAR_NEW_EFFECTS: 'sar-new-effects',
  SAR_NEW_SAR: 'sar-new-sar',
  SAR_NEW_TYPE: 'sar-new-type',
  SAR_NEW_USER: 'sar-new-user',
  TXS_MONITOR: 'txs-monitor',
  TXS_MONITOR_DASHBOARD: 'txs-monitor-dashboard',
  TXS_MONITOR_DETAIL: 'txs-monitor-detail',
  TXS_MONITOR_SCENARIO: 'txs-monitor-scenario',
  TXS_MONITOR_SCENARIO_DASHBOARD: 'txs-monitor-scenario-dashboard',
  TXS_MONITOR_SCENARIO_MANAGE: 'txs-monitor-scenario-manage',
  TXS_MONITOR_SCENARIO_MANAGE_DECISION: 'txs-monitor-scenario-manage-decision',
  TXS_MONITOR_SCENARIO_MANAGE_RULES: 'txs-monitor-scenario-manage-rules',
  TXS_MONITOR_SCENARIO_MANAGE_TRIGGER: 'txs-monitor-scenario-manage-trigger',
  VASP: 'vasp',
  VASP_DASHBOARD: 'vasp-dashboard',
  VASP_DETAIL: 'vasp-detail',
  VASP_MANAGE: 'vasp-manage',
  VASP_NEW: 'vasp-new',
  VERIFIABLE_CREDENTIALS: 'verifiable-credentials',
});

export default Object.freeze({
  NAME,
  PATH,
});
