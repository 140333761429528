const COURSE = Object.freeze({
  STATUS: {
    COMPLETED: 'completed',
    INITIAL: 'initial',
  },
});

const LESSON = Object.freeze({
  STATUS: {
    COMPLETED: 'completed',
    NOTARIZED: 'notarized',
    UNNOTARIZED: 'unnotarized',
  },
});

export default Object.freeze({
  COURSE,
  LESSON,
});
