import { APIS } from '@/constants';
import axios from 'axios';

const youTubeApi = {
  requestEmbedFromUrl(url) {
    return axios({
      url: `/oembed?url=${url}&format=json`,
      method: 'get',
      baseURL: APIS.BASE_URL.YOUTUBE,
    });
  },
};

export default youTubeApi;
