const API_URL = process.env.VUE_APP_API_URL;
const API_V1 = `${API_URL}/v1`;

const BASE_URL = Object.freeze({
  COURSES: `${API_V1}/courses`,
  YOUTUBE: 'https://www.youtube.com',
});

export default Object.freeze({
  API_URL,
  BASE_URL,
});
